
import CheckoutMainCardComponent from '@/components/Checkout/CheckoutMainCardComponent.vue'
import StoreDetailsMainCardComponent from '@/components/Checkout/StoreDetailsMainCardComponent.vue'
import FirebaseAnalyticsScreensNames from '@/firebase/firebaseAnalyticsScreensNames'
import routerManager from '@/routerManager'
import L from 'leaflet'
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
export default Vue.extend({
    components: { 
        "checkout-main-card-component": CheckoutMainCardComponent,
        "store-details-main-card-component": StoreDetailsMainCardComponent,
    },

    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContent"]),
        ...mapState("store", ["selectedStoreDetails"]),


    },

    methods: {
        ...mapMutations("firebase", ["LOG_FIREBASE_SCREEN"]),
        
        getMapMarkerIcon(): L.Icon {      
            return L.icon({
                iconUrl: this.selectedStoreDetails.storeMapMarkerImgUrl,
                iconSize: [50, 50],
                iconAnchor: [20, 20],
            });    
        },

        goToRouterBack() {
            routerManager.goBack();
        }
    },
    
    mounted() {
        // firebase analytics
        this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.storeDetailsPage); 
    }
    
})
